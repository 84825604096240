@import '/styles/base.scss';

.markdown {
  ol,
  ul {
    margin: 2.4rem 0 0 0.8rem;
    list-style-position: outside;

    li:not(:first-of-type) {
      margin-top: 0.4rem;
    }
  }

  ul {
    li {
      padding-left: 0.4rem;
      margin-left: 2.1rem;
      list-style-type: square;

      span {
        // center with bullet point
        position: relative;
        top: 0.1rem;
      }

      &::marker {
        font-size: 65%;
      }
    }
  }

  ol {
    li {
      padding-left: 0.1rem;
      margin-left: 2.3rem;
    }
  }

  .link {
    @include paragraph;
    text-decoration: underline;
  }

  h2 {
    margin-top: 4.8rem;

    @include md {
      margin-top: 6.4rem;
    }

    @include lg {
      margin-top: 8.8rem;
    }
  }

  h3 {
    margin-top: 4rem;
  }

  h4 {
    margin-top: 4rem;
  }

  p {
    margin-top: 2.4rem;
    &:first-child {
      margin-top: 0;
    }
  }

  h2 + h3,
  h3 + h4,
  h2 + h4,
  h2 + p,
  h3 + p {
    margin-top: 2.4rem;
  }

  strong,
  .bold {
    font-weight: 600;
    letter-spacing: -0.05rem;
  }
}
