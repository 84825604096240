@import '/styles/base.scss';

.title {
  & + .text,
  & + .link {
    margin-top: 2.4rem;
  }

  @include sectionTitle;
}

.text {
  @include lg {
    padding-right: 3.3rem;
  }

  & + .link {
    margin-top: 3.2rem;
  }
}

.textWithSpacing {
  @include md {
    padding-right: 4rem;
  }
}

.textWithDropcap > p:first-child {
  @include dropcap-paragraph-typography;
}

.childrenWrapper {
  @include sm-only {
    margin-top: 6.4rem;
  }
}
